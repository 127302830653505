import React from 'react'

import { Link } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { BaseCSS } from 'styled-bootstrap-grid'
import { Normalize } from 'styled-normalize'

const SideNav = styled.ul`
  text-transform: lowercase;
  list-style: none;
  text-align: right;
  margin-top: 2rem;
  margin-right: 1rem;
  a {
    text-decoration: none;
    color: #666;
  }
  li {
    margin-bottom: 0.5rem;
  }
  @media (max-width: 575px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 0;
    margin-top: 0.75rem;
    li {
      margin-left: 0.75rem;
      margin-bottom: 0;
    }
  }
`

const Main = styled.main`
  font-family: Geneva, ‘Lucida Sans’, ‘Lucida Grande’, ‘Lucida Sans Unicode’,
    Verdana, sans-serif;
  color: #111;
`

const Layout: React.SFC<{ children?: React.ReactNode }> = ({ children }) => {
  // paddingLeft avoids making content move when scrollbar is on screen https://stackoverflow.com/a/30293718/6088051
  return (
    <div style={{ paddingLeft: 'calc(100vw - 100%)' }}>
      <Container>
        <BaseCSS />
        <Normalize />
        <Row>
          <Col sm={3}>
            <SideNav>
              <li>
                <Link to="/">About</Link>
              </li>
              <li>
                <Link to="/bookshelf">Bookshelf</Link>
              </li>
              <li>
                <a href="https://twitter.com/eric__mckay">Twitter</a>
              </li>
            </SideNav>
          </Col>
          <Col sm={7}>
            <Main>{children}</Main>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Layout
